import React, { FC, useState } from 'react'
import cn from 'classnames'
import { ReactComponent as CaretUp } from '../icons/caret-up.svg'
import { ShortOrderJson } from '@umbrella/delivery-api-ts'
import { PossibleStatuses } from './Status'
import { formatCurrency } from '../util'
import { useTranslation } from 'react-i18next'

interface Props {
  data: ShortOrderJson
}

const isStatusFinal = (data: ShortOrderJson): boolean => {
  const found = data.statuses.find(
    (status) =>
      status.type === PossibleStatuses.REFUSED ||
      status.type === PossibleStatuses.REFUSED_BY_CLIENT ||
      status.type === PossibleStatuses.DELIVERED
  )
  return Boolean(found)
}

export const OrderDetails: FC<Props> = ({ data }) => {
  const { t } = useTranslation()
  const statusIsFinal = isStatusFinal(data)

  const [expanded, setExpanded] = useState(!statusIsFinal)

  const renderProducts = () => {
    return data.items.map((product: any) => {
      return <ProductCard key={product.name} product={product} locale={data.locale} />
    })
  }

  return (
    <>
      <div
        className="flex items-center justify-between py-4 border-t border-[#B1D9FF] cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <span className="font-medium">
          {expanded ? t('orderPage.heading.details.hide') : t('orderPage.heading.details.show')}
        </span>
        <CaretUp
          className={cn({
            'rotate-0': expanded,
            'rotate-180': !expanded,
          })}
        />
      </div>
      {expanded && (
        <>
          <div className="border-t border-[#B1D9FF] py-4">{renderProducts()}</div>
          <div className="border-t border-[#B1D9FF] py-4">
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="font-medium">{t('orderPage.heading.total')}</td>
                  <td className="text-right font-medium">
                    <span className="text-[12px] block line-through">
                      {formatCurrency(data.currency, data.locale, data.totalCost * 1.3)}
                    </span>
                    <strong className="text-[16px] font-extrabold block text-[#FF1818]">
                      {formatCurrency(data.currency, data.locale, data.totalCost)}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="border-t border-[#B1D9FF] py-4">
            <h4 className="font-medium">{t('orderPage.heading.address')}</h4>
            <span className="text-14">{data.address}</span>
          </div>
        </>
      )}
    </>
  )
}

interface ProductCardProps {
  product: any
  locale: string
}

const ProductCard: FC<ProductCardProps> = ({ product }) => {
  const { t } = useTranslation()

  const renderProductImage = () => {
    if (product.name.includes(' MX')) {
      return (
        <img
          className="w-full min-w-0"
          src={`/images/${product.name.replace(' ', '_')}.png`}
          alt=""
        />
      )
    }

    if (product.name.includes(' CL')) {
      return (
        <img
          className="w-full min-w-0"
          src={`/images/${product.name.replace(' CL', '_PE')}.png`}
          alt=""
        />
      )
    }

    return <img className="w-full min-w-0" src={`/images/${product.name}_PE.png`} alt="" />
  }

  return (
    <div className="flex mb-4">
      <div className="w-product-pic mr-2 shrink-0">{renderProductImage()}</div>
      <div>
        <h4 className="font-medium leading-tight">{product.name}</h4>
        <table className="mt-2 leading-tight table-fixed w-full">
          <tbody>
            <tr>
              <td className="font-medium">{t('orderPage.details.quantity')}</td>
              <td className="text-right">{product.qty}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
