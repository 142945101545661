import {
    ShortOrderJson,
    Configuration,
    OrderApi,
    PublicDeliveryV2Api,
    OrderstatusApi,
    ReceiptInfoJson,
} from '@umbrella/delivery-api-ts'
import {PossibleStatuses} from '../components/Status'

const prodPath = ''
// const prodPath = 'https://stage.um1pay.com/module/delivery'

const api = new OrderApi(new Configuration(), prodPath)
const publicApi = new PublicDeliveryV2Api(new Configuration(), prodPath)
const orderStatusApi = new OrderstatusApi(new Configuration(), prodPath)
// const api = new OrderApi(new Configuration(), stagePath)

const normalizeData = (data: ShortOrderJson): ShortOrderJson => {
    const possibleStatusesArray = Object.keys(PossibleStatuses)
    const normalizedStatuses = data.statuses.filter((status) =>
        possibleStatusesArray.includes(status.type)
    )
    return {
        ...data,
        statuses: normalizedStatuses,
    }
}

export const Api = {
    getClosestDeliveryDate: async (orderId: string) => {
        const res = await orderStatusApi.getClosestDeliveryDate(orderId)
        return res.data
    },
    getPaymentUploadLink: async (orderId: string) => {
        const res = await orderStatusApi.getPaymentUploadLink({orderId})
        return res.data
    },
    getReceiptsByOrderId: async (
        orderId: string
    ): Promise<ReceiptInfoJson[]> => {
        const res = await orderStatusApi.getReceiptsList(orderId)
        return res.data
    },
    getOrderByShortCode: async (shortCode: string): Promise<ShortOrderJson> => {
        const res = await api.getOrderByShortCode(shortCode.toUpperCase())

        // return normalizeData({
        //   id: '14bf9b7d-9b44-4be1-a4e2-d5028e5bfda0',
        //   shortCode: 'TMY-865-639',
        //   address:
        //     'MOLLENDO, ISLAY, AREQUIPA, 040701, Urb. Miramar Mz. F Lote 3; Calle Ricardo Palma/-17.025872811247602, -72.0094371452288',
        //   totalCost: 165,
        //   currency: 'pen',
        //   locale: 'es-PE',
        //   items: [{ name: 'Bioprost', qty: 1, price: 140, image: 'https://via.placeholder.com/60x90' }],
        //   statuses: [
        //     { type: 'CONFIRMED', timestamp: '2022-05-14T12:54:21.124372' },
        //     { type: 'VERIFIED', timestamp: '2022-05-14T12:54:21.139232' },
        //     { type: 'IN_TRANSIT', timestamp: '2022-05-15T02:02:00.451094' },
        //   ],
        //   deliveryCost: 25,
        //   canBeDeclined: true,
        // })
        return normalizeData(res.data)
    },

    cancelOrder: async (orderId: string, comment: string) => {
        const res = await publicApi.cancelByCustomer({id: orderId, comment})
        return res.data
    },
}
