import React, { FC, useEffect } from "react";
import { Header } from "./Header";

interface Props {
  shortCode?: string;
  region?: string;
}

export const Layout: FC<Props> = ({ children, region }) => {
  const setViewHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    setViewHeight();
    window.addEventListener("resize", () => {
      setViewHeight();
    });
    return () => {
      window.removeEventListener("resize", () => {
        setViewHeight();
      });
    };
  });

  return (
    <div className="min-h-screen flex flex-col">
      <Header region={region} />
      <div className="max-w-full mx-6 sm:max-w-md sm:m-auto flex-grow flex flex-col">
        {children}
      </div>
    </div>
  );
};
