import { ReactComponent as BackIcon } from '../icons/back-arrow.svg'
import React, { useEffect, useState } from 'react'
import { Api } from '../api'
import { ReceiptInfoJson } from '@umbrella/delivery-api-ts'
import { Receipt } from '../components/Receipt'
import { UploadReceipt } from '../components/UploadReceipt'
import useLocalStorage from 'use-local-storage'

interface Props {
  setIsVisible: (arg: boolean) => void
  orderId: string
  currency: string
  locale: string
}

export const ReceiptPage: React.FC<Props> = ({ setIsVisible, orderId, currency, locale }) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setReceiptUploaded] = useLocalStorage('receiptUploaded', false)

  const [isLoading, setIsLoading] = useState(false)
  const [receipts, setReceipts] = useState<ReceiptInfoJson[]>([])

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const receiptsList = await Api.getReceiptsByOrderId(orderId)
        setReceipts(receiptsList)
      } catch (e) {
      } finally {
        setIsLoading(false)
      }
    })()
  }, [orderId])

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <>
      <div className="flex items-center mt-[12px] mb-[32px]">
        <button onClick={() => setIsVisible(false)}>
          <BackIcon />
        </button>
        <h2 className="font-semibold ml-[12px]">Recibos</h2>
      </div>
      <>
        {!receipts.length && 'No receipts yet'}
        {receipts.map((receipt, index) => (
          <Receipt
            receipt={receipt}
            index={index + 1}
            key={receipt.paymentDocumentId}
            currency={currency}
            locale={locale}
          />
        ))}
      </>
      <UploadReceipt orderId={orderId} setReceiptUploaded={setReceiptUploaded} />
    </>
  )
}
