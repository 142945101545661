import React, { FC } from 'react'
import cn from 'classnames'

interface PaymentFooterProps {
  border?: boolean
}

export const PaymentFooter: FC<PaymentFooterProps> = ({ children, border = true }) => {
  return (
    <footer
      className={cn('w-full border-t-[#93C063] pt-[16px] pb-[4px] mt-auto', {
        'border-t': border,
      })}
    >
      {children}
      <span className="block text-right text-[#BFBFBF] text-[12px] mt-[7px]">
        Powered by Digital Supremacy
      </span>
    </footer>
  )
}
