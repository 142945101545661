import { MessageBirdButton } from "../MessageBirdButton";
import { PaymentFooter } from "../PaymentFooter";
import React, { useState } from "react";
import axios from "axios";
import { ClosestDeliveryDate } from "./ClosestDeliveryDate";

interface Props {
  orderId: string;
  country: string;
  setAlternativeMethodsVisible: (arg: boolean) => void;
}

export const WaitingForPayment: React.FC<Props> = ({
  orderId,
  setAlternativeMethodsVisible,
  country,
}) => {
  const [paymentInitLoading, setPaymentInitLoading] = useState(false);
  const isChile = country === "cl";

  const paymentInit = () => {
    setPaymentInitLoading(true);
    axios
      .post(`/module/stripe/api/v1/checkout`, {
        orderId,
      })
      .then((res) => {
        if (res?.data) {
          window.location.href = res.data;
        }
      })
      .catch(() => {
        alert("Something went wrong");
        setPaymentInitLoading(false);
      });
  };

  return (
    <PaymentFooter key="2">
      <span className="text-center block font-medium text-[18px] leading-[28px]">
        Page ahora para recibir el
      </span>
      <ClosestDeliveryDate shortCode={orderId} />
      <div className="mt-[12px]">
        {!isChile && (
          <button
            onClick={paymentInit}
            disabled={paymentInitLoading}
            className="mb-[12px] block text-center text-white bg-[#93C063] m-auto rounded-[6px] w-full py-[8px] border border-[#93C063] drop-shadow-[0_2px_0_rgba(0,0,0,0.043)]"
          >
            Pago con tarjeta
          </button>
        )}
        <button
          onClick={() => setAlternativeMethodsVisible(true)}
          className="mb-[12px] block text-center m-auto rounded-[6px] text-amber-50 w-full py-[8px] border bg-[#93C063] border-[#93C063] drop-shadow-[0_2px_0_rgba(0,0,0,0.016)]"
        >
          {isChile ? "Métodos de pago" : "Otros métodos de pago"}
        </button>
      </div>
      <MessageBirdButton
        className="block text-center text-[#067f52] m-auto py-[8px]"
        text="¿Necesita ayuda?"
      />
    </PaymentFooter>
  );
};
