import React from 'react'
import { ReactComponent as ClockIcon } from '../../icons/clock.svg'
import cn from 'classnames'
// import { useNavigate } from 'react-router-dom'

interface Props {
  setIsReceiptPageVisible: (arg: boolean) => void
}

export const ReceiptInReview: React.FC<Props> = ({ setIsReceiptPageVisible }) => {
  return (
    <div className="flex flex-col items-center">
      <AwaitingConfirmation />
      <button
        onClick={() => setIsReceiptPageVisible(true)}
        className={cn(
          'cursor-pointer mb-[12px] block text-center text-white bg-[#93C063] m-auto rounded-[6px] w-full py-[8px] border border-[#93C063] drop-shadow-[0_2px_0_rgba(0,0,0,0.043)]'
        )}
      >
        Ver mis recibos
      </button>
    </div>
  )
}

export const AwaitingConfirmation = () => {
  return (
    <>
      <ClockIcon />
      <span
        style={{ textAlign: 'center' }}
        className="font-medium mt-[12px] px-10  mb-[8px] block text-[18px]"
      >
        El pedido espera la confirmación del pago
      </span>
    </>
  )
}
