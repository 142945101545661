import { useTranslation } from 'react-i18next'
import { useState } from 'react'

export const BankDeposit = () => {
  const { t } = useTranslation()
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false)

  return (
    <>
      <button
        onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
        className={`my-4 border border-[#1890FF] bg-[${
          isDescriptionVisible ? '#1890FF' : ''
        }]  block w-full h-[44px] cursor-pointer flex items-center justify-center`}
      >
        <span style={{ color: isDescriptionVisible ? 'white' : '#1890FF' }}>
          {t('orderPage.alternativePaymentMethods.methods.deposit.title')}
        </span>
      </button>
      {isDescriptionVisible && (
        <>
          <p className={'text-base font-normal'}>
            También puedes pagar realizando un depósito bancario a nombre de{' '}
            <span className={'font-semibold'}>HILART SAC</span> en nuestra cuenta de:
          </p>
          <br />
          <p className={'text-base'}>
            <span className={'font-semibold'}>RUC: </span>20604986266
          </p>
          <br />
          <h3 className={'text-base font-semibold'}>BCP</h3>
          <p>
            Cuenta Corriente Soles: <span className={'font-semibold'}>191-2600210-0-47</span>
          </p>
          <br />
          <h3 className={'text-base font-semibold'}>CCI</h3>
          <p>
            Cuenta Corriente Soles: <span className={'font-semibold'}>002-19100260021004752</span>
          </p>
          <br />
          <p className={'text-lg font-normal italic text-red-600 font-semibold'}>
            Enviar foto del comprobante de pago o transferencia para concluir su compra!
          </p>
        </>
      )}
    </>
  )
}
