import { useTranslation } from 'react-i18next'
import { useState } from 'react'

export const YapePayment = () => {
  const { t } = useTranslation()
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false)
  return (
    <>
      <button
        onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
        className={`my-4 border border-[#1890FF] bg-[${
          isDescriptionVisible ? '#1890FF' : ''
        }]  block w-full h-[44px] cursor-pointer flex items-center justify-center`}
      >
        <span style={{ color: isDescriptionVisible ? 'white' : '#1890FF' }}>
          {t('orderPage.alternativePaymentMethods.methods.yape.title')}
        </span>
      </button>

      {isDescriptionVisible && (
        <>
          <p className={'text-base'}>
            Yapeanos al número <span className={'font-semibold'}>923-667-551</span> <br /> a nombre
            de <span className={'font-semibold'}>HILARTES SAC</span>
          </p>
          <br />
          <p className={'text-lg font-normal italic text-red-600 font-semibold'}>
            Enviar foto del comprobante de pago o transferencia para concluir su compra!
          </p>
        </>
      )}
    </>
  )
}
