export const formatCurrency = (isoCode: string, locale: string, value: number) => {
  const formatLocale = locale === 'es-MX' ? locale.split('-')[1] : locale

  return Intl.NumberFormat(formatLocale, {
    style: 'currency',
    currency: isoCode.toUpperCase(),
  }).format(value)
}

export const timeString12hr = (range: string) => {
  const [from, to] = range.split('-')
  const convert = (time: string) =>
    new Date('1970-01-01T' + (time.length === 4 ? '0' + time : time) + 'Z').toLocaleTimeString(
      'en-US',
      {
        timeZone: 'UTC',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
      }
    )

  return `${convert(from)}-${convert(to)}`
}
