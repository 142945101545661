import React, { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

declare global {
  interface Window {
    MessageBirdChatWidget: any
  }
}

interface MessageBirdButtonProps {
  className?: string
  text: string
}

export const MessageBirdButton: FC<MessageBirdButtonProps> = ({ className, text }) => {
  const [params] = useSearchParams()
  const shortCode = params.get('shortCode')

  useEffect(() => {
    ;(async () => {
      try {
        await window.MessageBirdChatWidget?.init()
        window.MessageBirdChatWidget.on('ready', () => {
          console.log('ready')
          window.MessageBirdChatWidget?.setAttributes({
            shortCode,
          })
        })
        console.log('init')
      } catch (err) {
        console.log(err)
      }
    })()

    // @ts-ignore
    // eslint-disable-next-line
  }, [shortCode, window.MessageBirdChatWidget])

  const callMessagebird = () => {
    // @ts-ignore
    window.MessageBirdChatWidget.toggleChat()
  }

  return (
    <button className={className} onClick={callMessagebird}>
      {text}
    </button>
  )
}
