import React from "react";
import logoSrc from "../icons/hilart-logo.png";
import jilartoLogo from "../icons/jilarito-logo.png";

interface Props {
  region?: string;
}

export const Header = ({ region }: Props) => {
  const isChile = region === "cl";

  return (
    <div style={{ minHeight: 35 }} className="py-2 bg-[#067F52]">
      {!!region && (
        <div className="max-w-full mx-6 sm:max-w-md sm:m-auto">
          <img
            width="141"
            height="35"
            src={isChile ? jilartoLogo : logoSrc}
            alt="HILART SAC"
          />
        </div>
      )}
    </div>
  );
};
