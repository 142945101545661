import React from "react";
import { BankDeposit } from "./BankDeposit";
import { BankDepositChile } from "./BankDepositChile";
import { YapePayment } from "./YapePayment";

interface Props {
  country: string;
}

export const AlternativePaymentMethods = ({ country }: Props) => {
  const isChile = country === "cl";

  return (
    <div className="mb-4">
      {isChile ? <BankDepositChile /> : <BankDeposit />}
      {!isChile && <YapePayment />}
    </div>
  );
};
