import React, { useEffect } from "react";
import { Layout } from "./Layout";
import { PaymentFooter } from "./PaymentFooter";
import { ReactComponent as PaymentFailedIcon } from "../icons/payment-failed.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

interface Props {
  region?: string;
}

export const PaymentResultFailed = ({ region }: Props) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const shortCode = params.get("shortCode");

  const goToStatus = () => {
    navigate(`/details?shortCode=${shortCode}`);
  };

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      goToStatus();
    }, 5000);
    return () => {
      clearTimeout(redirectTimeout);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout region={region}>
      <div className="flex-grow flex flex-col items-center justify-between pt-[16px]">
        <div className="flex items-center justify-center flex-col flex-grow">
          <PaymentFailedIcon />
          <h2 className="font-medium text-[24px]">¡Pago fue rechazado!</h2>
          <span className="text-center max-w-[300px]">
            Pedido sin pago será cancelado. Por favor, intentar de nuevo.
          </span>
        </div>
        <PaymentFooter border={false}>
          <button
            onClick={goToStatus}
            className="flex-shrink-0 mb-[12px] block text-center text-white bg-[#93C063] m-auto rounded-[6px] w-full py-[8px] border border-[#93C063] drop-shadow-[0_2px_0_rgba(0,0,0,0.043)]"
          >
            Intentar de nuevo
          </button>
        </PaymentFooter>
      </div>
    </Layout>
  );
};
