import { ReactComponent as BackIcon } from "../icons/back-arrow.svg";
import { PaymentFooter } from "../components/PaymentFooter";
import { MessageBirdButton } from "../components/MessageBirdButton";
import React from "react";
import { UploadReceipt } from "../components/UploadReceipt";

interface Props {
  setAlternativeMethodsVisible: (arg: boolean) => void;
  orderId: string;
  setReceiptUploaded: (arg: boolean) => void;
}

export const AlternativePaymentsPageChile: React.FC<Props> = ({
  setAlternativeMethodsVisible,
  setReceiptUploaded,
  orderId,
}) => {
  return (
    <div className="payment-max-height">
      <div className="flex items-center mt-[12px] mb-[32px]">
        <button onClick={() => setAlternativeMethodsVisible(false)}>
          <BackIcon />
        </button>
        <h2 className="font-semibold ml-[12px]">Métodos de pago </h2>
      </div>
      <h3 className="font-bold  mb-[8px]">
        1. Transferencia bancaria o deposito bancario usando los datos de la
        empresa:
      </h3>
      <p className="text-[14px]">BANCO SANTANDER</p>
      <p className="text-[14px]">SOCIEDAD COMERCIALIZADORA JILARITO SPA</p>
      <p className="text-[14px]">77517432-3</p>
      <p className="text-[14px]">CTA CORRIENTE</p>
      <p className="text-[14px]">N° 0-000-8922549-3</p>
      <p className="text-[14px]">
        <a
          target="_blank"
          rel="noreferrer"
          href="mailto:contabilidadjilarito@gmail.com"
          className={"underline text-green-700"}
        >
          CONTABILIDADJILARITO@GMAIL.COM
        </a>
      </p>
      <div className="flex items-center gap-x-1 mt-[12px]">
        <h3 className="font-bold">2. WEBPAY:</h3>
        <p className="text-[14px]">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.webpay.cl/company/134864?utm_source=transbank&utm_medium=portal3.0&utm_campaign=link_portal"
            className={"underline text-green-700"}
          >
            LINK
          </a>
        </p>
      </div>
      <strong className="text-[14px] font-bold mb-[24px] mt-[20px]">
        ❗ENVIAR FOTO DEL COMPROBANTE DE PAGO O TRANSFERENCIA PARA CONCLUIR SU
        COMPRA❗
      </strong>

      <PaymentFooter border={false}>
        <UploadReceipt
          setReceiptUploaded={setReceiptUploaded}
          orderId={orderId}
        />

        <MessageBirdButton
          className="block text-center text-[#067f52] m-auto py-[8px]"
          text="¿Necesita ayuda?"
        />
      </PaymentFooter>
    </div>
  );
};
