import React, { useState } from 'react'
import { ReceiptInfoJson, PaymentDocumentStatus } from '@umbrella/delivery-api-ts'
import { ReactComponent as CaretIcon } from '../icons/receipt-caret.svg'
import { ReactComponent as ReceiptApprovedIcon } from '../icons/receipt-status-approved.svg'
import { ReactComponent as ReceiptPendingIcon } from '../icons/receipt-status-pending.svg'
import { ReactComponent as ReceiptDeclinedIcon } from '../icons/receipt-status-declined.svg'
import { format } from 'date-fns'
import { formatCurrency } from '../util'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

interface Props {
  receipt: ReceiptInfoJson
  index: number
  currency: string
  locale: string
}

// enum PaymentStatus {
//   Pending = 'PENDING',
//   Approved = 'APPROVED',
//   Declined = 'DECLINED',
// }

const ReceiptIconMapper = (status: PaymentDocumentStatus) => {
  switch (status) {
    case 'APPROVED':
      return <ReceiptApprovedIcon />
    case 'DECLINED':
      return <ReceiptDeclinedIcon />
    case 'PENDING':
      return <ReceiptPendingIcon />
    default:
      return null
  }
}

export const Receipt: React.FC<Props> = ({ receipt, index, currency, locale }) => {
  const [expanded, setExpanded] = useState(true)
  const { t } = useTranslation()

	return (
    <div className="border-b border-[rgba(6,127,82,0.34)] mb-4 pb-4">
      <button
        className="flex w-full justify-between items-center"
        onClick={() => setExpanded(!expanded)}
      >
        {ReceiptIconMapper(receipt.status)}
        <span className="font-medium text-[14px] mr-auto ml-[12px]">Recibo #{index}</span>
        <CaretIcon
          className={cn(
            {
              'rotate-0': expanded,
              'rotate-180': !expanded,
            },
            'transition-all'
          )}
        />
      </button>
      {expanded && (
        <div className="mt-4">
          <span className="block font-medium text-[14px]">Fecha de carga</span>
          <span className="block text-[14px]">
            {format(new Date(receipt.created), 'dd/MM/yyyy')}
          </span>

          {receipt.status === PaymentDocumentStatus.Approved && (
            <>
              <span className="block font-medium text-[14px] mt-[12px]">Monto</span>
              <span className="block text-[14px]">
                {/* cents div */}
                {formatCurrency(currency, locale, receipt.amount / 100)}
              </span>
            </>
          )}
          {receipt.status === PaymentDocumentStatus.Declined && (
            <>
              <span className="block font-medium text-[14px] mt-[12px]">Razón de rechazo</span>
							{/*// @ts-ignore*/}
              <span className="block text-[14px]">{t(receipt.comments)}</span>
            </>
          )}
        </div>
      )}
    </div>
  )
}
