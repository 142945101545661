import { ReactComponent as BackIcon } from "../icons/back-arrow.svg";
import { PaymentFooter } from "../components/PaymentFooter";
import { MessageBirdButton } from "../components/MessageBirdButton";
import React from "react";
import { UploadReceipt } from "../components/UploadReceipt";

interface Props {
  setAlternativeMethodsVisible: (arg: boolean) => void;
  orderId: string;
  setReceiptUploaded: (arg: boolean) => void;
}

export const AlternativePaymentsPage: React.FC<Props> = ({
  setAlternativeMethodsVisible,
  setReceiptUploaded,
  orderId,
}) => {
  return (
    <div className="payment-max-height">
      <div className="flex items-center mt-[12px] mb-[32px]">
        <button onClick={() => setAlternativeMethodsVisible(false)}>
          <BackIcon />
        </button>
        <h2 className="font-semibold ml-[12px]">Otros métodos de pago </h2>
      </div>
      <h3 className="font-bold  mb-[8px]">1. Depósito Bancario</h3>
      <p className="text-[14px]">
        También puedes pagar realizando un depósito bancario a nombre de HILART
        SAC en nuestra cuenta de:
      </p>
      <p className="text-[14px]">BCP</p>
      <p className="text-[14px]">Cuenta Corriente Soles: 191-2600210-0-47</p>
      <p className="text-[14px]">CCI Cuenta Corriente Soles:</p>
      <p className="text-[14px]">002-19100260021004752</p>
      <strong className="text-[14px] font-bold mb-[24px] mt-[20px]">
        ❗ENVIAR FOTO DEL COMPROBANTE DE PAGO O TRANSFERENCIA PARA CONCLUIR SU
        COMPRA❗
      </strong>
      <h3 className="font-bold mb-[8px]">2. YAPE (Válido solo para Perú)</h3>
      <p className="text-[14px]">
        Yapeanos al número 923-667-551 a nombre de Hilartes SAC
      </p>
      <strong className="text-[14px] font-bold mb-[24px] mt-[20px]">
        ❗ENVIAR FOTO DEL COMPROBANTE DE PAGO O TRANSFERENCIA PARA CONCLUIR SU
        COMPRA❗
      </strong>

      <PaymentFooter border={false}>
        <UploadReceipt
          setReceiptUploaded={setReceiptUploaded}
          orderId={orderId}
        />

        <MessageBirdButton
          className="block text-center text-[#067f52] m-auto py-[8px]"
          text="¿Necesita ayuda?"
        />
      </PaymentFooter>
    </div>
  );
};
