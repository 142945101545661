import React, { ChangeEvent, useState } from "react";
import { Api } from "../api";
import axios from "axios";
import cn from "classnames";

interface Props {
  orderId: string;
  setReceiptUploaded: (arg: boolean) => void;
}

export const UploadReceipt: React.FC<Props> = ({
  orderId,
  setReceiptUploaded,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files && e.target.files[0];
    if (!file) {
      setError(true);
      return;
    }
    try {
      setLoading(true);
      const uploadUrl = await Api.getPaymentUploadLink(orderId);
      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        await axios({
          url: uploadUrl,
          method: "put",
          data: reader.result,
          headers: {
            "Content-Type": file.type || "application/octet-stream",
            "x-goog-meta-orderid": orderId,
            "x-goog-meta-whodid": "order-status-page@umbrella-trade.com",
            "x-goog-meta-doctype": "DIRECT_PAYMENT_RECEIPT",
          },
        });
        setReceiptUploaded(true);
        setTimeout(() => window.location.reload(), 300);
      });
      reader.readAsArrayBuffer(file);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <>
      {error && (
        <span className="text-center my-2 block text-red-400">
          Error uploading
        </span>
      )}
      <label
        className={cn(
          "cursor-pointer mb-[12px] block text-center text-white bg-[#93C063] m-auto rounded-[6px] w-full py-[8px] border border-[#93C063] drop-shadow-[0_2px_0_rgba(0,0,0,0.043)]",
          {
            "pointer-events-none": loading,
            "opacity-40": loading,
          }
        )}
      >
        <input
          onChange={onChange}
          hidden
          type="file"
          accept={"image/*,.pdf"}
          multiple={false}
        />
        {loading ? "Loading..." : "Adjuntar el recibo"}
      </label>
    </>
  );
};
