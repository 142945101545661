import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import './i18n'
import './index.css'
import { OrderStatusPage } from './pages/OrderStatusPage'
import ReactGA from 'react-ga4'

ReactGA.initialize('G-MNE5CS6EWN')

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/details" />} />
        <Route path="/details" element={<OrderStatusPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('app')
)
