import React from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { CountdownRendererFn } from 'react-countdown/dist/Countdown'
import { Trans } from 'react-i18next'

export const PaymentCountdown = () => {
  const renderer: CountdownRendererFn = ({ hours, minutes, seconds }) => {
    return (
      <span className="text-[23px] text-[#FF1818]">
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    )
  }

  const minutesLeft = 60 - new Date().getMinutes()
  const secondsLeft = 60 - new Date().getSeconds()

  const date = new Date(
    Date.now() + (minutesLeft > 10 ? minutesLeft : minutesLeft + 60) * 1000 * 60
  ).setSeconds(secondsLeft)

  return (
    <div className="flex flex-wrap items-center justify-center">
      <Trans
        i18nKey={'orderPage.countdown.warning'}
        components={[
          <span className="text-[#FF1818] text-center block" />,
          <Countdown date={date} renderer={renderer} />,
          <span className="text-[#FF1818] text-[23px]" />,
        ]}
      />
    </div>
  )
}
