import React, { useEffect, useState } from "react";
import { OrderDetails } from "../components/OrderDetails";
import { Status } from "../components/Status";
import { Layout } from "../components/Layout";
import { useSearchParams } from "react-router-dom";
import { ShortCodeForm } from "../components/ShortCodeForm";
import { Api } from "../api";
import { ShortOrderJson } from "@umbrella/delivery-api-ts";
import { useTranslation } from "react-i18next";
// import { CancelOrder } from '../components/CancelOrder'
import ReactGA from "react-ga4";
import { PaymentPage } from "./PaymentPage";
import { PaymentFooter } from "../components/PaymentFooter";
import { MessageBirdButton } from "../components/MessageBirdButton";
import { PaymentResultSuccess } from "../components/PaymentResultSuccess";
import { PaymentResultFailed } from "../components/PaymentResultFailed";
import { OrderTracking } from "../components/OrderTracking";

export const OrderStatusPage = () => {
  const [params, setParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ShortOrderJson>();
  const [error, setError] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    ReactGA.send("pageview");
  }, []);

  const shortCode = params.get("shortCode");
  const payment = params.get("payment");

  useEffect(() => {
    if (shortCode) {
      setLoading(true);
      Api.getOrderByShortCode(shortCode)
        .then((data) => {
          setData(data);
        })
        .catch((err) => {
          console.log(err);
          setError(t("orderPage.errors.wrongCode"));
        })
        .finally(() => setLoading(false));
    }
  }, [shortCode, t]);

  const isDeposit = (statuses: ShortOrderJson["statuses"]): boolean => {
    return statuses[statuses.length - 1].type === "DEPOSIT";
  };

  const renderStatusPage = () => {
    if (!data) return null;
    return (
      <>
        <h2 className="font-medium my-4">
          {t("orderPage.heading.order")} {data.shortCode}
        </h2>
        <OrderTracking
          trackingLink={data.trackingLink}
          externalId={data.externalId}
        />
        <Status
          country={data.country}
          preferredDate={data.preferredDate}
          preferredDeliveryTime={data.preferredDeliveryTime}
          statuses={data.statuses}
          orderId={data.id}
          locale={data.locale}
        />
        <OrderDetails data={data} />
        {/*{data?.canBeDeclined && <CancelOrder orderId={data.id} />}*/}
        <PaymentFooter>
          <MessageBirdButton
            className="block text-center text-[#067f52] m-auto py-[8px]"
            text="¿Necesita ayuda?"
          />
        </PaymentFooter>
      </>
    );
  };

  if (payment === "success") {
    return <PaymentResultSuccess region={data?.country} />;
  }

  if (payment === "cancel") {
    return <PaymentResultFailed region={data?.country} />;
  }

  return (
    <Layout region={data?.country} shortCode={data?.shortCode}>
      {shortCode && !loading && !error && data ? (
        isDeposit(data.statuses) ? (
          <PaymentPage data={data} />
        ) : (
          renderStatusPage()
        )
      ) : (
        <ShortCodeForm
          loading={loading}
          error={error}
          setError={setError}
          setParams={setParams}
        />
      )}
    </Layout>
  );
};
