import { formatCurrency } from '../../util'
import React from 'react'
import { ShortOrderJson } from '@umbrella/delivery-api-ts'
import { Address } from './Address'
import { Product } from './Product'

interface Props {
  data: ShortOrderJson
}

export const PaymentPageOrderDetails: React.FC<Props> = ({ data }) => {
  return (
    <>
      <div className="payment-max-height">
        <h2 className="opacity-90 font-bold mb-[16px]">Pedido {data.shortCode}</h2>
        <h3 className="font-medium mb-[8px]">Dirección de entrega</h3>
        <Address address={data.address} />
        <div className="pt-[16px] pb-[8px]">
          {data.items.map((item, index) => (
            <Product key={index} product={item} />
          ))}
          <span className="font-bold block text-right text-[14px]">
            Descuento {formatCurrency(data.currency, data.locale, data.totalCost * 0.3)}
          </span>
          <span className="font-bold block text-right text-[14px] mt-[4px]">
            Total {formatCurrency(data.currency, data.locale, data.totalCost)}
          </span>
        </div>
      </div>
    </>
  )
}
