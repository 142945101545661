import cn from "classnames";
import { useTranslation } from "react-i18next";

interface Props {
    externalId?: string;
    trackingLink?: string
}

export const OrderTracking = ({ trackingLink, externalId }: Props) => {
    const {t} = useTranslation();

    const onTrackingClick = () => {
        if (trackingLink) {
            window.open(trackingLink, '_blank')
        }

    }

    if (trackingLink) {
        return (
            <button  className={cn(
                'cursor-pointer mb-[12px] block text-center text-white bg-[#93C063] m-auto rounded-[6px] w-full py-[8px] border border-[#93C063] drop-shadow-[0_2px_0_rgba(0,0,0,0.043)]'
            )} onClick={onTrackingClick}>
                {t('orderPage.heading.trackOrder')}
            </button>
        );
    }

    return <>{externalId}</>;
};
