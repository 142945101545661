import React, { FC, useState } from "react";
import { ShortOrderJsonStatusJson } from "@umbrella/delivery-api-ts";
import cn from "classnames";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { ReactComponent as Star } from "../icons/star.svg";
import { ReactComponent as Success } from "../icons/success.svg";
import { ReactComponent as Cancel } from "../icons/cancel.svg";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { PaymentCountdown } from "./PaymentCountdown";
import { AlternativePaymentMethods } from "./AlternativePaymentMethods/AlternativePaymentMethods";

interface Props {
  statuses: ShortOrderJsonStatusJson[];
  orderId: string;
  locale: string;
  preferredDate: string;
  preferredDeliveryTime: string;
  country: string;
}

export const PossibleStatuses = {
  CONFIRMED: "CONFIRMED",
  DEPOSIT: "DEPOSIT", // кнопка stripe
  VERIFIED: "VERIFIED",
  VERIFIED_DEPOSIT: "VERIFIED_DEPOSIT",
  IN_TRANSIT: "IN_TRANSIT",
  DELIVERED: "DELIVERED",
  REFUSED: "REFUSED",
  REFUSED_BY_CLIENT: "REFUSED_BY_CLIENT",
  CANCEL_ON_DELIVERY: "CANCEL_ON_DELIVERY",
};

const statusExist = (statuses: ShortOrderJsonStatusJson[], type: string) => {
  const found = statuses.find((status) => status.type === type);
  return Boolean(found);
};

export const Status: FC<Props> = ({
  statuses,
  orderId,
  locale,
  preferredDate,
  preferredDeliveryTime,
  country,
}) => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const shortCode = params.get("shortCode");
  // const env = params.get('env')
  const payment = params.get("payment");

  const successFormsUrl = `https://docs.google.com/forms/d/e/1FAIpQLSeju7Ohq1JHHkv3hIV_nqgmQ03AbkbArp_FoUOjwK8btn0lDA/viewform?usp=pp_url&entry.312151340=${shortCode}`;
  const refusedFormsUrl = `https://docs.google.com/forms/d/e/1FAIpQLSdDjGjQ-FI2Fwg_Ggs-pfJ69mwXc4UjSeiHWcdTs02pZkYv8A/viewform?usp=pp_url&entry.1223500353=${shortCode}`;

  const [paymentInitLoading, setPaymentInitLoading] = useState(false);
  const paymentInit = () => {
    setPaymentInitLoading(true);
    axios
      .post(`/module/stripe/api/v1/checkout`, {
        orderId,
      })
      .then((res) => {
        if (res?.data) {
          window.location.href = res.data;
        }
      })
      .catch(() => {
        alert("Something went wrong");
        setPaymentInitLoading(false);
      });
  };

  const renderStatusCards = () => {
    if (payment) {
      return null;
    }

    if (
      (locale === "es-MX" || locale === "es-PE") &&
      statusExist(statuses, PossibleStatuses.DEPOSIT) &&
      !statusExist(statuses, PossibleStatuses.VERIFIED)
    ) {
      return (
        <>
          <PaymentCountdown />
          {!payment && preferredDate && (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div>
                <span>{`${t("orderPage.deliveryDate")} `}</span>
                <span style={{ fontSize: "17px", fontWeight: "500" }}>
                  {preferredDate}, {preferredDeliveryTime}
                </span>
              </div>
            </div>
          )}
          <button
            onClick={paymentInit}
            disabled={paymentInitLoading}
            type="submit"
            className="my-4 disabled:bg-[#AFAFAF] bg-[#1890FF] block w-full h-[44px] cursor-pointer text-white flex items-center justify-center"
          >
            {paymentInitLoading
              ? t("orderPage.message.pleaseWait")
              : t("orderPage.payment.required")}
          </button>
          {locale === "es-PE" && (
            <AlternativePaymentMethods country={country} />
          )}
        </>
      );
    }

    if (statusExist(statuses, PossibleStatuses.DELIVERED)) {
      return (
        <>
          <StatusCard
            statuses={statuses}
            watchFor={PossibleStatuses.DELIVERED}
            description={t("orderPage.statuses.delivered")}
            lastChild
          />
          <a
            target="_blank"
            rel="noreferrer"
            href={successFormsUrl}
            className="mb-4 flex items-center justify-center h-[44px] bg-[#19B600] text-white rounded-sm"
          >
            <Star className="mr-2" />
            <span>{t("orderPage.googleForms.delivered")}</span>
          </a>
        </>
      );
    }
    if (
      statusExist(statuses, PossibleStatuses.REFUSED) ||
      statusExist(statuses, PossibleStatuses.REFUSED_BY_CLIENT) ||
      statusExist(statuses, PossibleStatuses.CANCEL_ON_DELIVERY)
    ) {
      return (
        <>
          <StatusCard
            statuses={statuses}
            watchFor={[
              PossibleStatuses.REFUSED,
              PossibleStatuses.REFUSED_BY_CLIENT,
              PossibleStatuses.CANCEL_ON_DELIVERY,
            ]}
            description={t("orderPage.statuses.refused")}
            lastChild
            refused
          />
          <a
            target="_blank"
            rel="noreferrer"
            href={refusedFormsUrl}
            className="mb-4 flex items-center justify-center h-[44px] bg-[#19B600] text-white rounded-sm"
          >
            {t("orderPage.googleForms.refused")}
          </a>
        </>
      );
    }
    return (
      <>
        <StatusCard
          statuses={statuses}
          watchFor={[PossibleStatuses.CONFIRMED, PossibleStatuses.DEPOSIT]}
          description={t("orderPage.statuses.confirmed")}
        />
        <StatusCard
          statuses={statuses}
          watchFor={[
            PossibleStatuses.VERIFIED,
            PossibleStatuses.VERIFIED_DEPOSIT,
          ]}
          description={t("orderPage.statuses.verified")}
        />
        <StatusCard
          statuses={statuses}
          watchFor={PossibleStatuses.IN_TRANSIT}
          description={t("orderPage.statuses.transit")}
        />
        <StatusCard
          statuses={statuses}
          watchFor={PossibleStatuses.DELIVERED}
          description={t("orderPage.statuses.delivered")}
          lastChild
        />
        {/*<span className="block border-b border-[#B1D9FF]" />*/}
        {/*<ChangeStatusNotificationControl />*/}
      </>
    );
  };

  return (
    <div className="border-t border-[#B1D9FF] pt-4">
      <PaymentResult />
      {renderStatusCards()}
    </div>
  );
};

const PaymentResult = () => {
  const [params] = useSearchParams();
  const payment = params.get("payment");
  const { t } = useTranslation();

  if (payment === "success") {
    return (
      <div>
        <Success className="w-24 m-auto" />
        <p className="my-2 text-center font-medium leading-tight">
          {t("orderPage.payment.accepted")}
        </p>
      </div>
    );
  }

  if (payment === "cancel") {
    return (
      <div>
        <Cancel className="w-24 m-auto fill-red-500" />
        <p className="my-2 text-center font-medium leading-tight">
          {t("orderPage.payment.declined")}
        </p>
      </div>
    );
  }

  return null;
};

interface StatusCardProps {
  statuses: ShortOrderJsonStatusJson[];
  watchFor: string | string[];
  description: string;
  lastChild?: boolean;
  refused?: boolean;
}

const StatusCard: FC<StatusCardProps> = ({
  statuses,
  lastChild,
  watchFor,
  description,
  refused,
}) => {
  // preventing mutation:
  const reversedStatuses = [...statuses].reverse();
  const statusExist = reversedStatuses.find((item) =>
    Array.isArray(watchFor)
      ? watchFor.includes(item.type)
      : watchFor === item.type
  );
  const isCurrent = Array.isArray(watchFor)
    ? watchFor.includes(reversedStatuses[0].type)
    : watchFor === reversedStatuses[0].type;

  return (
    <div
      className={cn(
        "border rounded-sm flex h-[44px] px-2 py-1 items-center justify-between mb-4 relative",
        {
          "text-[#1890ff] border-[#1890ff]": isCurrent && !refused,
          "text-[#737373] border-[#737373]": !isCurrent,
          "text-black border-black": refused,
        }
      )}
    >
      <span>{description}</span>
      {statusExist && (
        <div className="flex justify-end flex-col items-end">
          <span className="block leading-tight text-14">
            {format(new Date(statusExist.timestamp), "HH:mm")}
          </span>
          <span className="block leading-tight text-14">
            {format(new Date(statusExist.timestamp), "dd/MM/yyyy")}
          </span>
        </div>
      )}

      {!lastChild && (
        <span
          className={cn(
            "absolute w-4 border-t border-dashed  left-[50%] bottom-[-9px] rotate-90 translate-x-[-50%]",
            {
              "border-[#1890ff]": isCurrent,
              "border-[#737373]": !isCurrent,
            }
          )}
        />
      )}
    </div>
  );
};
