import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Layout } from "./Layout";
import { PaymentFooter } from "./PaymentFooter";
import { ReactComponent as PaymentSuccessIcon } from "../icons/payment-success.svg";
import { useSearchParams } from "react-router-dom";

export const PaymentResultSuccess = ({ region }: { region?: string }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const shortCode = params.get("shortCode");

  const goToStatus = () => {
    navigate(`/details?shortCode=${shortCode}`);
    window.location.reload();
  };

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      goToStatus();
    }, 5000);
    return () => {
      clearTimeout(redirectTimeout);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout region={region}>
      <div className="flex-grow flex flex-col items-center justify-between pt-[16px]">
        <div className="flex items-center justify-center flex-col flex-grow">
          <PaymentSuccessIcon />
          <h2 className="font-medium text-[24px]">El pedido está pagado!</h2>
          <span className="text-center max-w-[200px]">
            Haga click en el botón para seguimiento de pedido
          </span>
        </div>
        <PaymentFooter border={false}>
          <button
            onClick={goToStatus}
            className="flex-shrink-0 mb-[12px] block text-center text-white bg-[#93C063] m-auto rounded-[6px] w-full py-[8px] border border-[#93C063] drop-shadow-[0_2px_0_rgba(0,0,0,0.043)]"
          >
            Seguimiento mi pedido
          </button>
        </PaymentFooter>
      </div>
    </Layout>
  );
};
