import React, { useState } from 'react'
import cn from 'classnames'

interface Props {
  address: string
}

export const Address: React.FC<Props> = ({ address }) => {
  const [isFull, setIsFull] = useState(false)
  return (
    <span
      onClick={() => setIsFull(!isFull)}
      className={cn('select-none text-[14px] block', {
        truncate: !isFull,
      })}
    >
      {address}
    </span>
  )
}
