import React, { FC, useEffect, useState } from "react";
import { ShortOrderJson } from "@umbrella/delivery-api-ts";
import useLocalStorage from "use-local-storage";
import { AlternativePaymentsPage } from "./AlternativePaymentsPage";
import { PaymentPageOrderDetails } from "../components/PaymentPageOrderDetails/PaymentPageOrderDetails";
import { PaymentPageFooter } from "../components/PaymentPageFooter/PaymentPageFooter";
import { ReceiptPage } from "./ReceiptPage";
import { AlternativePaymentsPageChile } from "./AlternativePaymentPageChile";

interface PaymentPageProps {
  data: ShortOrderJson;
}

export const PaymentPage: FC<PaymentPageProps> = ({ data }) => {
  const [receiptUploaded, setReceiptUploaded] = useLocalStorage(
    "receiptUploaded",
    false
  );
  const [alternativeMethodsVisible, setAlternativeMethodsVisible] = useState(
    false
  );
  const [isReceiptPageVisible, setIsReceiptPageVisible] = useState(false);
  const isChile = data.country === "cl";

  useEffect(() => {
    if (receiptUploaded) {
      setAlternativeMethodsVisible(false);
    }
  }, [receiptUploaded]);

  if (isReceiptPageVisible) {
    return (
      <ReceiptPage
        setIsVisible={setIsReceiptPageVisible}
        orderId={data.id}
        currency={data.currency}
        locale={data.locale}
      />
    );
  }

  if (alternativeMethodsVisible) {
    return isChile ? (
      <AlternativePaymentsPageChile
        setAlternativeMethodsVisible={setAlternativeMethodsVisible}
        orderId={data.id}
        setReceiptUploaded={setReceiptUploaded}
      />
    ) : (
      <AlternativePaymentsPage
        setAlternativeMethodsVisible={setAlternativeMethodsVisible}
        orderId={data.id}
        setReceiptUploaded={setReceiptUploaded}
      />
    );
  } else {
    return (
      <>
        <PaymentPageOrderDetails data={data} />
        <PaymentPageFooter
          country={data.country}
          currency={data.currency}
          locale={data.locale}
          setIsReceiptPageVisible={setIsReceiptPageVisible}
          setAlternativeMethodsVisible={setAlternativeMethodsVisible}
          orderId={data.id}
          orderPaymentInfo={data.orderPaymentInfo}
        />
      </>
    );
  }
};
