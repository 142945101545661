import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './locales/en/translation.json'
import es from './locales/es/translation.json'

const Detector = new LanguageDetector(i18n, {
  caches: [],
})

i18n
  .use(Detector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
    },
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
