import { useTranslation } from "react-i18next";
import { useState } from "react";

export const BankDepositChile = () => {
  const { t } = useTranslation();
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsDescriptionVisible(!isDescriptionVisible)}
        className={`my-4 border border-[#1890FF] bg-[${
          isDescriptionVisible ? "#1890FF" : ""
        }]  block w-full h-[44px] cursor-pointer flex items-center justify-center`}
      >
        <span style={{ color: isDescriptionVisible ? "white" : "#1890FF" }}>
          {t("orderPage.alternativePaymentMethods.methods.deposit.title")}
        </span>
      </button>
      {isDescriptionVisible && (
        <>
          <p className={"text-base font-normal"}>
              Transferencia bancaria o deposito bancario usando los datos de la empresa:
            <span className={"font-semibold"}>HILART SAC</span> en nuestra
            cuenta de:
          </p>
          <br />
          <p className={"text-base"}>
            <span className={"font-semibold"}>BANCO SANTANDER</span>
          </p>
          <br />
          <h3 className={"text-base font-semibold"}>SOCIEDAD COMERCIALIZADORA JILARITO SPA</h3>
          <p>
            <span className={"font-semibold"}>77517432-3</span>
          </p>
          <br />
          <h3 className={"text-base font-semibold"}>CTA CORRIENTE</h3>
          <p>
            <span className={"font-semibold"}>002-N° 0-000-8922549-3</span>
          </p>
          <br />
          <p
            className={"text-lg font-normal italic text-red-600 font-semibold"}
          >
            Enviar foto del comprobante de pago o transferencia para concluir su
            compra!
          </p>
        </>
      )}
    </>
  );
};
