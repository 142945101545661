import React, { ChangeEvent, FC, useEffect, useRef } from 'react'
import cn from 'classnames'
import { useSearchParams } from 'react-router-dom'

interface Props {
  setCode: (code: string[]) => void
  setError: (val: string) => void
  code: string[]
  error: boolean
}

const dashedCodeRegEx = /[\w.]{3}-[\w.]{3}-[\w.]{3}/gi
const plainCodeRegEx = /[\w.]{9}/gi

export const ShortCodeInput: FC<Props> = ({ code, setCode, error, setError }) => {
  const [params] = useSearchParams()
  const shortCode = params.get('shortCode')

  useEffect(() => {
    if (shortCode && dashedCodeRegEx.test(shortCode)) {
      setCode(shortCode.split('-'))
    }
  }, [shortCode, setCode])

  const onChange = (e: ChangeEvent, position: number) => {
    setError('')
    const { value } = e.target as HTMLInputElement
    if (value.length === 3 && position !== 2) {
      refs[position + 1].current!.focus()
    }
    const newCodeArray = [...code]
    newCodeArray[position] = value
    setCode(newCodeArray)
  }

  const onKeyDown = (e: any, position: number) => {
    setError('')
    if (e.code === 'Backspace') {
      if (!refs[position].current!.value && position !== 0) {
        refs[position - 1].current!.focus()
      }
    }
  }

  const refs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ]

  const onPaste = async (e: any) => {
    e.preventDefault()
    setError('')
    const val = await navigator.clipboard.readText()
    const isDashedCode = dashedCodeRegEx.test(val)
    const isPlainCode = plainCodeRegEx.test(val)
    if (isDashedCode) {
      setCode(val.split('-'))
    } else if (isPlainCode) {
      setCode(val.match(/.{1,3}/g)!)
    } else {
      alert('Your clipboard does not contain valid shortcode. Please type manually.')
    }
  }

  return (
    <div className="flex justify-between w-full">
      <input
        ref={refs[0]}
        maxLength={3}
        onChange={(e) => onChange(e, 0)}
        onPaste={onPaste}
        value={code[0]}
        onKeyDown={(e) => onKeyDown(e, 0)}
        className={cn('uppercase border w-1/4 shrink-0 focus:outline-0 text-center text-2xl', {
          'border-[#19B600]': refs[0].current?.value?.length !== 3,
          'border-[#1890FF]': refs[0].current?.value?.length === 3,
          'border-[#FF4D4F]': error,
          'text-[#FF4D4F]': error,
        })}
        type="text"
      />
      <span
        className={cn('text-2xl mb-1 mx-2', {
          'text-[#1890FF]': !error,
          'text-[#FF4D4F]': error,
        })}
      >
        -
      </span>
      <input
        ref={refs[1]}
        maxLength={3}
        onChange={(e) => onChange(e, 1)}
        onPaste={onPaste}
        value={code[1]}
        onKeyDown={(e) => onKeyDown(e, 1)}
        className={cn('uppercase border w-1/4 shrink-0 focus:outline-0 text-center text-2xl', {
          'border-[#19B600]': refs[1].current?.value?.length !== 3,
          'border-[#1890FF]': refs[1].current?.value?.length === 3,
          'border-[#FF4D4F]': error,
          'text-[#FF4D4F]': error,
        })}
        type="text"
      />
      <span
        className={cn('text-2xl mb-1 mx-2', {
          'text-[#1890FF]': !error,
          'text-[#FF4D4F]': error,
        })}
      >
        -
      </span>
      <input
        ref={refs[2]}
        maxLength={3}
        onChange={(e) => onChange(e, 2)}
        onPaste={onPaste}
        value={code[2]}
        onKeyDown={(e) => onKeyDown(e, 2)}
        className={cn('uppercase border w-1/4 shrink-0 focus:outline-0  text-center text-2xl', {
          'border-[#19B600]': refs[2].current?.value?.length !== 3,
          'border-[#1890FF]': refs[2].current?.value?.length === 3,
          'border-[#FF4D4F]': error,
          'text-[#FF4D4F]': error,
        })}
        type="text"
      />
    </div>
  )
}
