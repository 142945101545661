import React, { FC, useEffect, useState } from 'react'
import { ShortCodeInput } from './ShortCodeInput'
import { ReactComponent as Spinner } from '../icons/spinner.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  setParams: (object: Record<string, string>) => void
  setError: (val: string) => void
  error?: string
  loading?: boolean
}

export const ShortCodeForm: FC<Props> = ({ setParams, error, setError, loading }) => {
  const [disabled, setDisabled] = useState(false)
  const [code, setCode] = useState(['', '', ''])
  const { t } = useTranslation()

  useEffect(() => {
    setDisabled(code.toString().length !== 11)
  }, [code])

  return (
    <>
      <h2 className="my-4 uppercase text-[#19B600] text-2xl text-center">
        {t('orderPage.heading.main')}
      </h2>
      <ShortCodeInput error={Boolean(error)} setError={setError} setCode={setCode} code={code} />
      {error && <span className="text-14 text-[#FF4D4F]">{error}</span>}
      <button
        disabled={disabled || loading}
        className="my-4 disabled:bg-[#AFAFAF] bg-[#1890FF] block w-full h-[44px] cursor-pointer text-white flex items-center justify-center"
        onClick={() =>
          setParams({
            shortCode: code.join('-'),
          })
        }
      >
        <span>{t('orderPage.submit')}</span>
        {loading && <Spinner className="ml-2 w-8 h-8" />}
      </button>
    </>
  )
}
