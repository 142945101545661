import React, { FC } from 'react'
import { PaymentFooter } from './PaymentFooter'
import { MessageBirdButton } from './MessageBirdButton'
import cn from 'classnames'
import { formatCurrency } from '../util'

interface Props {
  paidAmount: number
  pendingAmount: number
  setIsReceiptPageVisible: (arg: boolean) => void
  currency: string
  locale: string
}

export const ReceiptPartiallyPaid: FC<Props> = ({
  paidAmount,
  pendingAmount,
  setIsReceiptPageVisible,
  currency,
  locale,
}) => {
  const paidAmountPercent = (paidAmount / (paidAmount + pendingAmount)) * 100

  return (
    <PaymentFooter key="3">
      <div className="h-[35px] bg-[rgba(245,34,45,0.1)] rounded-full mb-[16px] relative">
        <span
          className="leading-[35px] text-center text-[16px] text-white rounded-full h-full bg-[#067F52] block"
          style={{
            width: paidAmountPercent + '%',
          }}
        >
          {paidAmountPercent > 30 ? 'Pagada' : ''}
        </span>
        <span className="absolute leading-[35px] text-center text-[16px] text-[#F5222D] right-[16px] top-0">
          {formatCurrency(currency, locale, pendingAmount / 100)}
        </span>
      </div>
      <span className="block mb-[24px] text-[18px] font-medium text-center">
        Su pedido no pagada en su totelidad. Pague el saldo, por favor
      </span>
      <button
        onClick={() => setIsReceiptPageVisible(true)}
        className={cn(
          'cursor-pointer mb-[12px] block text-center text-white bg-[#93C063] m-auto rounded-[6px] w-full py-[8px] border border-[#93C063] drop-shadow-[0_2px_0_rgba(0,0,0,0.043)]'
        )}
      >
        Ver mis recibos
      </button>
      <MessageBirdButton
        className="block text-center text-[#067f52] m-auto py-[8px]"
        text="¿Necesita ayuda?"
      />
    </PaymentFooter>
  )
}
