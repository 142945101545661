import React, { useEffect, useState } from 'react'
import { Api } from '../../api'
import { format } from 'date-fns'
import { timeString12hr } from '../../util'

interface Props {
  shortCode: string
}

export const ClosestDeliveryDate: React.FC<Props> = ({ shortCode }) => {
  const [date, setDate] = useState<{ deliveryDate: string; deliveryTime: string } | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const date = await Api.getClosestDeliveryDate(shortCode)
        setDate(date)
      } catch (err) {
        setError(true)
      } finally {
        setLoading(false)
      }
    })()
  }, [shortCode])

  if (loading) {
    return <span className="delivery-date">Loading...</span>
  }

  if (error) {
    return <span className="delivery-date">No dates found</span>
  }

  if (date) {
    return (
      <span className="delivery-date">
        {format(new Date(date.deliveryDate), 'dd/MM/yy')} {timeString12hr(date.deliveryTime)}
      </span>
    )
  }

  return null
}
