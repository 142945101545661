import { FC } from 'react'
import { ShortOrderJsonOrderItemJson } from '@umbrella/delivery-api-ts'

interface Props {
  product: ShortOrderJsonOrderItemJson
}

export const Product: FC<Props> = ({ product }) => {
  const renderProductImage = () => {
    if (product.name.includes(' MX')) {
      return (
        <img
          width="40"
          height="64"
          src={`https://um1pay.com/images/${product.name.replace(' ', '_')}.png`}
          alt=""
        />
      )
    }

    if (product.name.includes(' CL')) {
      return (
        <img
          width="40"
          height="64"
          src={`https://um1pay.com/images/${product.name.replace(' CL', '_PE')}.png`}
          alt=""
        />
      )
    }

    return (
      <img width="40" height="64" src={`https://um1pay.com/images/${product.name}_PE.png`} alt="" />
    )
  }

  return (
    <div className="flex justify-between mb-[12px] items-center">
      <div className="mr-[8px]">{renderProductImage()}</div>
      <span className="font-medium mr-auto">{product.name}</span>
      <span className="font-medium text-[#067F52]">x{product.qty}</span>
    </div>
  )
}
