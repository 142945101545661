import React from "react";
import { PaymentFooter } from "../PaymentFooter";
import { MessageBirdButton } from "../MessageBirdButton";
import { ReceiptInReview } from "./ReceiptInReview";
import { WaitingForPayment } from "./WaitingForPayment";
import {
  OrderPaymentInfo,
  OrderPaymentStatus,
} from "@umbrella/delivery-api-ts";
import { ReceiptPartiallyPaid } from "../ReceiptPartiallyPaid";

interface Props {
  orderPaymentInfo: OrderPaymentInfo;
  orderId: string;
  setAlternativeMethodsVisible: (arg: boolean) => void;
  setIsReceiptPageVisible: (arg: boolean) => void;
  currency: string;
  locale: string;
  country: string;
}

export const PaymentPageFooter: React.FC<Props> = ({
  orderPaymentInfo,
  orderId,
  setAlternativeMethodsVisible,
  setIsReceiptPageVisible,
  currency,
  locale,
  country,
}) => {
  if (orderPaymentInfo.paymentStatus === OrderPaymentStatus.WaitingForPayment) {
    return (
      <WaitingForPayment
        country={country}
        orderId={orderId}
        setAlternativeMethodsVisible={setAlternativeMethodsVisible}
      />
    );
  }

  if (
    orderPaymentInfo.paymentStatus === OrderPaymentStatus.OrderPartiallyPaid
  ) {
    return (
      <ReceiptPartiallyPaid
        setIsReceiptPageVisible={setIsReceiptPageVisible}
        paidAmount={orderPaymentInfo.paidAmount}
        pendingAmount={orderPaymentInfo.pendingAmount}
        currency={currency}
        locale={locale}
      />
    );
  }

  return (
    <PaymentFooter key="1">
      <ReceiptInReview setIsReceiptPageVisible={setIsReceiptPageVisible} />
      <MessageBirdButton
        className="block text-center text-[#067f52] m-auto py-[8px]"
        text="¿Necesita ayuda?"
      />
    </PaymentFooter>
  );
};
